import React from "react"
// import Layout from "../layouts/index"
import { Container } from "react-bootstrap"
import SectionHeader from "../components/section-header"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ContentPage = ({pageContext}) => {
    
    const titel = pageContext.titel
    const content = pageContext.content
    const backButtonPath = pageContext.backButtonPath
    const textAlign = pageContext.textAlign
    
    return(
        <Layout>
            <SEO title={`${titel}`} /> 
            <Container>
                <SectionHeader titel={titel} description={content} textAlign={textAlign} path={backButtonPath}></SectionHeader>
            </Container>
        </Layout>
    )
}

export default ContentPage